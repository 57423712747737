import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import isRole from './../helpers/roles'
import Customer from './customer/'

import PrivateRoute from './../components/routeUtils/privateRoute'
import NotFound from './../components/routeUtils/notFound'

import Login from './../components/pages/Login/'
import NavBar from './../components/navBar/'

import CustomerSignup from './../components/customer/CustomerSignup'
import CustomerContainer from './../containers/customersContainer'
import TeamsContainer from './../containers/teamsContainer'

import ProjectsContainer from '../containers/projectsContainer'
import ProjectContainer from '../containers/projectContainer'

import Settings from './../components/pages/Settings'
import Profile from './../components/pages/Profile'
import ForgotPassword from './pages/ForgotPassword'
import Audit from './pages/Audit/'
import BugTracker from '../components/sharedComponents/BugTracker'
import Container from '../components/sharedComponents/Container'
import Header from '../components/sharedComponents/Header'

import ViewerProvider from './../contexts/ViewerContext'

// import Scheduler from './../components/scheduler/Scheduler'
import SchedulerContainer from './../containers/schedulerContainer'
import Production from './pages/Production/index'
import MainContent from './../components/sharedComponents/layouts/MainContent'

class Routes extends Component {
  componentDidMount() {
    this.props.checkAuthStatus()
  }

  render() {
    const {
      user,
      loggedIn,
      updateUser,
      userUpdated,
      changePassword,
      logoutUser,
      loading,
      error,
    } = this.props

    return (
      <Router>
        <main>
          <NavBar user={user} logoutUser={logoutUser} loggedIn={loggedIn} />

          <Switch>
            <Route
              exact={true}
              path="/login"
              render={props => <Login {...props} {...this.props} />}
            />

            {/* for sandboxing */}
            {/* <Route
              exact={true}
              path="/analytics"
              render={props => <Analytics {...props} {...this.props} />}
            /> */}

            <Route
              exact={true}
              path="/forgot-password"
              render={props => <ForgotPassword {...props} />}
            />

            <Route path="/customer/new" render={props => <CustomerSignup {...props} />} />

            {/* <Route
              path="/schedule"
              render={props => (
                <MainContent smallNav={user ? false : true}>
                  <Header title="Select the time that would work best for your free on-site survey." />
                  <Scheduler project={null} isFromQuote={true} user={this.props.user} {...props} />
                </MainContent>
              )}
            /> */}

            <Route
              path="/schedule"
              render={props => (
                <MainContent smallNav={true} fullWidth={true}>
                  {/* <div className="notranslate"> */}
                  <SchedulerContainer
                    project={null}
                    isFromQuote={true}
                    user={user}
                    smallNav={user ? false : true}
                    {...props}
                  />
                  {/* </div> */}
                </MainContent>
              )}
            />

            <PrivateRoute
              exact={true}
              path="/settings"
              component={Settings}
              loggedIn={loggedIn}
              loading={loading}
              error={error}
              updateUser={updateUser}
              userUpdated={userUpdated}
              changePassword={changePassword}
              user={user}
            />

            <PrivateRoute
              exact={true}
              path="/teams"
              component={TeamsContainer}
              loggedIn={loggedIn}
              user={user}
            />

            <PrivateRoute
              exact={true}
              path="/profile"
              component={Profile}
              loggedIn={loggedIn}
              user={user}
            />

            <Route
              exact={true}
              path="/project/:id/:slug/audit"
              render={props => <Audit {...props} {...this.props} />}
            />

            <Route
              exact={true}
              path="/project/:id/:slug"
              render={props =>
                user && loggedIn ? (
                  user.role === 'Customer' ? (
                    <CustomerContainer {...props} user={user} />
                  ) : (
                    <ViewerProvider>
                      <ProjectContainer {...this.props} {...props} />
                    </ViewerProvider>
                  )
                ) : (
                  <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
              }
            />

            <Route
              path="/"
              render={props => {
                if (user && loggedIn) {
                  if (user.role === 'Customer') {
                    return <CustomerContainer {...props} user={user} />
                  } else if (user.role === 'Contributor' || user.role === 'Admin') {
                    if (isRole(user).production) {
                      return <Production {...props} user={user} />
                    } else {
                      return <ProjectsContainer {...props} user={user} />
                    }
                  }
                } else {
                  return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }
              }}
            />

            <Route component={NotFound} />
          </Switch>
          {loggedIn && user.role !== 'Customer' && !isRole(user).production && <BugTracker />}
        </main>
      </Router>
    )
  }
}

export default Routes
