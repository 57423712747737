import React, { useState, useEffect } from 'react'
import * as Production from './styles'

const _Production = props => {
  return (
    <Production.Layout>
      <h1>hello world!</h1>
    </Production.Layout>
  )
}

export default _Production
