import React from 'react'
import styled from 'styled-components'
import { lighten, darken } from 'polished'
import {
  NotificationsOutlined,
  Home,
  Build,
  People,
  Notifications,
  Description,
  Create,
  LocalAtm,
  CreditCard,
  Add,
  CameraAlt,
  UnfoldMore,
} from '@material-ui/icons'
import { colors } from './../../helpers/foundation'

// ACTIVITIES ICON

const StyledAlarmWithBackground = styled.div`
  position: relative;
  font-size: 28px;
  color: ${colors.primary};
  width: 48px;
  height: 48px;
  text-align: center;
  background-color: ${lighten(0.4, colors.primary)};
  border-radius: 50%;
  .outer {
    position: relative;
    top: 4px;
  }
`
export const AlarmWithBackground = props => {
  const { color } = props
  return (
    <StyledAlarmWithBackground>
      <div className="outer">
        <NotificationsOutlined fontSize="inherit" color="inherit" />
      </div>
    </StyledAlarmWithBackground>
  )
}

// NAV ICONS

const StyledNavIcon = styled.div`
  font-size: 18px;
  color: ${colors.grey2};
`
export const HomeIcon = props => {
  return (
    <StyledNavIcon>
      <Home color="inherit" />
    </StyledNavIcon>
  )
}
export const ToolIcon = props => {
  return (
    <StyledNavIcon>
      <Build color="inherit" />
    </StyledNavIcon>
  )
}
export const PeopleIcon = props => {
  return (
    <StyledNavIcon>
      <People color="inherit" />
    </StyledNavIcon>
  )
}
export const NotificationsIcon = props => {
  return (
    <StyledNavIcon>
      <Notifications color="inherit" />
    </StyledNavIcon>
  )
}

// TAB ICONS

const StyledTabIcons = styled.div``

export const SurveyIcon = props => {
  /*
  possibles
  - assignment
  - assignment_ind
  - schedule? the clock
  - list_alt
  - create
  */
  return (
    <StyledTabIcons>
      <Create color="inherit" fontSize="small" />
    </StyledTabIcons>
  )
}

export const EstimateIcon = props => {
  return (
    <StyledTabIcons>
      <LocalAtm color="inherit" fontSize="small" />
    </StyledTabIcons>
  )
}

export const PaperworkIcon = props => {
  return (
    <StyledTabIcons>
      <Description color="inherit" fontSize="small" />
    </StyledTabIcons>
  )
}

export const InstallationIcon = props => {
  return (
    <StyledTabIcons>
      <Build color="inherit" fontSize="small" />
    </StyledTabIcons>
  )
}

// DASHBOARD ICONS

const StyledPlusIcon = styled.div`
  font-size: ${props => props.fontSize && `${props.fontSize}px`};
  ${props => {
    if (props.vAlignSvg) {
      return `
        > svg {
          vertical-align: bottom;
        }
      `
    }
  }}
`
export const PlusIcon = props => {
  return (
    <StyledPlusIcon {...props}>
      <Add color="inherit" fontSize="inherit" />
    </StyledPlusIcon>
  )
}

const StyledPlusIconCircle = styled.div`
  cursor: pointer;
  width: ${props => props.width && `${props.width}px`};
  height: ${props => props.height && `${props.height}px`};
  color: ${props => props.color && colors[props.color]};
  background-color: ${props => props.backgroundColor && colors[props.backgroundColor]};
  border-radius: 50%;
  text-align: center;

  transform: rotate(${props => props.isOpen && '45deg'});
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus,
  &.active {
    background-color: ${props => darken(0.05, colors[props.backgroundColor])};
  }
`
export const PlusIconCircle = props => {
  return (
    <StyledPlusIconCircle onClick={props.handleClick} {...props}>
      <PlusIcon {...props} color="inherit" vAlignSvg={true} />
    </StyledPlusIconCircle>
  )
}

export const UnfoldMoreIcon = props => {
  return <UnfoldMore color="inherit" fontSize="inherit" />
}

// SURVEY ICONS

export const CameraIcon = props => {
  return <CameraAlt color="inherit" fontSize="inherit" />
}
