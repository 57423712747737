import { connect } from 'react-redux'
import Estimate from './../components/estimate/'
import { estimateGet, estimateUpdateServer } from '../redux/modules/estimate/'

const mapStateToProps = state => {
  return {
    loading: state.estimate.loading,
    messages: state.estimate.messages,
    estimate: state.estimate.estimate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    estimateGet: estimateId => dispatch(estimateGet(estimateId)),
    estimateUpdateServer: (estimateId, updates, isCustomer, projectId) =>
      dispatch(estimateUpdateServer(estimateId, updates, isCustomer)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Estimate)
