import axios from 'axios'
import { addProductionUrl } from '../../../helpers/constants'
// import { UPDATE_INSTALLATION } from '../installation/types'
import produce from 'immer'
import { unionBy } from 'lodash'

import { updateCurrentProject } from '../project/actions'
// Loading all projects
export const ITEMS_HAS_ERRORED = 'app/projects/ITEMS_HAS_ERRORED'
export const ITEMS_IS_LOADING = 'app/projects/ITEMS_IS_LOADING'
export const ITEMS_FETCH_DATA_SUCCESS = 'app/projects/ITEMS_FETCH_DATA_SUCCESS'
export const FETCH_GLOBAL_SETTINGS_SUCCESS = 'app/projects/FETCH_GLOBAL_SETTINGS_SUCCESS'
export const ITEMS_ADD = 'app/projects/ITEMS_ADD'

// Update single project
// Needs loading state and error state
export const UPDATE_PROJECT = 'app/projects/UPDATE_PROJECT'
// export const VIEW_PROJECT = 'app/projects/VIEW_PROJECT'
export const UPDATE_SURVEY = 'app/projects/UPDATE_SURVEY'
export const UPDATE_ESTIMATE = 'app/projects/UPDATE_ESTIMATE'
export const UPDATE_INSTALLATION = 'app/projects/UPDATE_INSTALLATION'
export const UPDATE_STAGE = 'app/projects/UPDATE_STAGE'
// export const UPDATE_SURVEY_PART = 'app/projects/UPDATE_SURVEY_PART'
export const ITEMS_FETCH_QUOTES_SUCCESS = 'app/projects/ITEMS_FETCH_QUOTES_SUCCESS'
export const DELETE_PROJECT = 'app/projects/DELETE_PROJECT'
export const DELETE_QUOTE = 'app/projects/DELETE_QUOTE'
export const RESET_STAGES_FETCHED = 'app/projects/RESET_STAGES_FETCHED'

const initialState = {
  loading: false,
  error: null,
  items: [],
  stagesFetched: {
    survey: false,
    estimate: false,
    paperwork: false,
    installation: false,
  },
  quotes: [],
  // currentProject: null,
  globalSettings: {},
}

const projectsReducer = (state = initialState, action = {}) => {
  let projIdx, newProj
  switch (action.type) {
    case ITEMS_ADD:
      return {
        ...state,
        items: [...state.items, action.item],
      }
    case ITEMS_HAS_ERRORED:
      return {
        ...state,
        error: action.error,
      }
    case ITEMS_IS_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    case ITEMS_FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        items: unionBy(state.items, action.items, '_id'),
        stagesFetched: {
          ...state.stagesFetched,
          [action.stage]: true,
        },
      }
    case ITEMS_FETCH_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.quotes,
      }
    case UPDATE_PROJECT:
      projIdx = state.items.findIndex(item => item._id === action.project._id)
      newProj = action.project

      return {
        ...state,
        items: [...state.items.slice(0, projIdx), newProj, ...state.items.slice(projIdx + 1)],
      }
    case UPDATE_SURVEY:
      // find the particular project and update it
      projIdx = state.items.findIndex(
        item => item.survey._id === action.survey._id || item.survey === action.survey._id
      )
      newProj = state.items[projIdx]
      newProj.survey = action.survey
      return {
        ...state,
        items: [...state.items.slice(0, projIdx), newProj, ...state.items.slice(projIdx + 1)],
      }
    case UPDATE_ESTIMATE:
      // find the particular project and update it
      // console.log('state items?', state)
      if (state.items.length < 1) {
        return state
      } else {
        projIdx = state.items.findIndex(item => item.estimate._id === action.estimate._id)
        // console.log('proj idx', projIdx)
        newProj = state.items[projIdx]
        newProj.estimate = action.estimate
        return {
          ...state,
          items: [...state.items.slice(0, projIdx), newProj, ...state.items.slice(projIdx + 1)],
        }
      }
    case UPDATE_INSTALLATION:
      projIdx = state.items.findIndex(item => {
        if (item.installation === undefined || !item.installation) {
          return false
        }
        return item.installation._id === action.installationId
      })
      newProj = state.items[projIdx]
      return {
        ...state,
        items: [
          ...state.items.slice(0, projIdx),
          {
            ...newProj,
            installation: {
              ...newProj.installation,
              ...action.updates,
            },
          },
          ...state.items.slice(projIdx + 1),
        ],
      }
    case UPDATE_STAGE:
      projIdx = state.items.findIndex(item => item._id === action.projectId)
      return {
        ...state,
        items: [
          ...state.items.slice(0, projIdx),
          {
            ...state.items[projIdx],
            stage: action.newStage,
          },
          ...state.items.slice(projIdx + 1),
        ],
      }

    case DELETE_PROJECT:
      projIdx = state.items.findIndex(item => item._id === action.projectId)
      return {
        ...state,
        items: [...state.items.slice(0, projIdx), ...state.items.slice(projIdx + 1)],
      }

    case DELETE_QUOTE:
      // project this variable even though we are looking for the quoteIdx
      projIdx = state.quotes.findIndex(item => item._id === action.quoteId)
      return {
        ...state,
        quotes: [...state.quotes.slice(0, projIdx), ...state.quotes.slice(projIdx + 1)],
      }
    case RESET_STAGES_FETCHED:
      return {
        ...state,
        items: [],
        quotes: [],
        stagesFetched: {
          survey: false,
          estimate: false,
          paperwork: false,
          installation: false,
        },
      }
    // case UPDATE_SURVEY_PART:
    //   projIdx = state.items.findIndex(item => item._id === action.projectId)
    //   // console.log('huh from upd survey part', projIdx, action)
    //   // console.log(state.items[projIdx])
    //   // console.log(state.items[projIdx].survey)
    //   return produce(state, draftState => {
    //     draftState.items[projIdx].survey.status = action.status
    //   })

    // case VIEW_PROJECT:
    //   return {
    //     ...state,
    //     currentProject: action.project,
    //   }
    default:
      return state
  }
}

export function itemsFetchData(stage) {
  return dispatch => {
    dispatch(itemsIsLoading(true))

    axios
      .get(`${addProductionUrl()}/api/projects?stage=${stage}`)
      .then(items => {
        dispatch(itemsFetchDataSuccess(items.data, stage))
        dispatch(itemsIsLoading(false))
      })
      .catch(error => dispatch(itemsHasErrored(error)))
  }
}

export function itemsFetchQuotes() {
  return async dispatch => {
    try {
      dispatch(itemsIsLoading(true))
      const response = await axios.get(`${addProductionUrl()}/api/quotes?q=isValid`)
      if (response.statusText === 'OK') {
        // dispatch(itemsIsLoading(false))
        dispatch(itemsFetchQuotesSuccess(response.data))
      } else {
        throw Error('Something went wrong.')
      }
    } catch (err) {
      dispatch(itemsHasErrored('Error loading quote'))
      dispatch(itemsIsLoading(false))
    }
  }
}

export function resetStagesFetched() {
  return {
    type: RESET_STAGES_FETCHED,
  }
}

export function itemsFetchQuotesSuccess(quotes) {
  return {
    type: ITEMS_FETCH_QUOTES_SUCCESS,
    quotes,
  }
}

export function addItem(item) {
  return {
    type: ITEMS_ADD,
    item,
  }
}

export function itemsHasErrored(error) {
  return {
    type: ITEMS_HAS_ERRORED,
    error,
  }
}

export function itemsIsLoading(bool) {
  return {
    type: ITEMS_IS_LOADING,
    loading: bool,
  }
}

export function itemsFetchDataSuccess(items, stage) {
  return {
    type: ITEMS_FETCH_DATA_SUCCESS,
    items,
    stage,
  }
}

export function fetchGlobalSettingsSuccess(globalSettings) {
  return {
    type: FETCH_GLOBAL_SETTINGS_SUCCESS,
    globalSettings,
  }
}

export function updateSurvey(newSurvey) {
  return {
    type: UPDATE_SURVEY,
    survey: newSurvey,
  }
}

// export function updateSurveyPart(projectId, status) {
//   return {
//     type: UPDATE_SURVEY_PART,
//     projectId,
//     status,
//   }
// }

export function updateEstimate(newEstimate) {
  return {
    type: UPDATE_ESTIMATE,
    estimate: newEstimate,
  }
}

export function updateProject(project) {
  return {
    type: UPDATE_PROJECT,
    project: project,
  }
}

// export function setCurrentProject(project) {
//   return {
//     type: VIEW_PROJECT,
//     project,
//   }
// }

export function updateInstallation(installationId, updates) {
  return {
    type: UPDATE_INSTALLATION,
    installationId,
    updates,
  }
}

export function projectUpdateStage(projectId, newStage) {
  return {
    type: UPDATE_STAGE,
    projectId,
    newStage,
  }
}

export function removeProject(projectId) {
  return {
    type: DELETE_PROJECT,
    projectId,
  }
}

export function removeQuote(quoteId) {
  return {
    type: DELETE_QUOTE,
    quoteId,
  }
}

export function employeeGetEmployees(typeOfEmployee) {
  return dispatch => {
    return axios
      .get(`${addProductionUrl()}/api/users?type=${typeOfEmployee}`)
      .then(res => {
        if (res.status === 200) {
          return res.data
        } else {
          return 'error'
        }
      })
      .catch(err => {
        throw err
      })
  }
}

export function employeeAddMember(projectId, memberId, type) {
  return async dispatch => {
    try {
      const project = await axios.post(`${addProductionUrl()}/api/projects/${projectId}/members`, {
        member: memberId,
        team: type,
      })

      // TODO: This will need to change.
      // It seems strange that we get the project and dispatch
      // Would make more sense if this were split out, to much going on here.

      if (type === 'survey') {
        // TODO: Not updating the project for the customer.
        // Probably because project for customer is recieved in seperate reducer
        dispatch(updateProject(project.data.project))
      }

      if (type === 'estimate') {
        await axios.patch(`${addProductionUrl()}/api/estimates/${project.data.project.estimate}`, {
          status: 'To Do',
        })

        const updatedProject = await axios.get(`${addProductionUrl()}/api/projects/${projectId}`)

        dispatch(updateProject(updatedProject.data))
        return true
      }
    } catch (err) {
      throw err
    }
  }
}

export function employeeMarkSurvey(project, type, notes) {
  return async dispatch => {
    try {
      // add the revision notes to the object if needed
      const updateObj =
        type === 'To Revise'
          ? {
              from: 'To Review',
              status: 'To Revise',
              revisionNotes: notes,
              lastStatus: Date.now(),
              projectId: project._id,
            }
          : {
              from: 'To Review',
              status: 'Completed',
              lastStatus: Date.now(),
              projectId: project._id,
            }

      if (updateObj.status === 'Completed') {
        const updatedProject = await axios.patch(
          `${addProductionUrl()}/api/projects/${project._id}`,
          {
            stage: 'estimate',
          }
        )

        const updatedSurvey = await axios.patch(
          `${addProductionUrl()}/api/surveys/${project.survey._id}`,
          updateObj
        )

        // The projects array in state
        dispatch(updateProject(updatedProject.data.project._id))

        // The currently viewed project
        dispatch(
          updateCurrentProject({ ...updatedProject.data.project, survey: updatedSurvey.data })
        )
      } else {
        const updatedSurvey = await axios.patch(
          `${addProductionUrl()}/api/surveys/${project.survey._id}`,
          updateObj
        )

        dispatch(
          updateCurrentProject({
            ...project,
            survey: updatedSurvey.data,
          })
        )
      }
    } catch (err) {
      console.log(err)
    }
  }
}

export function projectUpdate(projectId, updates) {
  // TODO: Loading state and error handling
  return async dispatch => {
    try {
      const response = await axios.patch(`${addProductionUrl()}/api/projects/${projectId}`, updates)
      dispatch(updateProject(response.data.project))
      return true
    } catch (err) {
      throw err
    }
  }
}

// used to populate the full project -> survey -> material, etc... in Survey.Review
// If we do this call anyways why bother filtering it from items earlier?
// We should just set the current project in state with all the population from the project view
// export function getPopulatedProject(projectId) {
//   return async dispatch => {
//     try {
//       const project = await axios.get(`${addProductionUrl()}/api/projects/${projectId}`)
//       return project
//     } catch (err) {
//       throw err
//     }
//   }
// }

export function duplicateProject(projectId) {
  return async dispatch => {
    try {
      const project = await axios.post(`${addProductionUrl()}/api/projects`, {
        duplicate: true,
        projectId,
      })

      dispatch(addItem(project.data))

      return project.data
    } catch (err) {
      throw err
    }
  }
}

export function deleteProject(projectId) {
  return async dispatch => {
    try {
      await axios.delete(`${addProductionUrl()}/api/projects/${projectId}`)

      dispatch(removeProject(projectId))

      return true
    } catch (err) {
      throw err
    }
  }
}

export function deleteQuote(quoteId) {
  return async dispatch => {
    try {
      await axios.delete(`${addProductionUrl()}/api/quotes/${quoteId}`)

      dispatch(removeQuote(quoteId))

      return true
    } catch (err) {
      throw err
    }
  }
}

export default projectsReducer
