import React, { Component } from 'react'

import Header from './../sharedComponents/Header'
import Container from './../sharedComponents/Container'
import Button from './../sharedComponents/Button'

class EstimateStart extends Component {
  render() {
    return(
      <div>
        <Header title="Start the estimation." />
        <Container>
          <Button
            variant="lg"
            text="Start Estimation"
            handleClick={this.props.startEstimation}
          />
        </Container>
      </div>
    )
  }
}

export default EstimateStart