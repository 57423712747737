import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { getToken, setAuthorizationToken } from './../helpers/auth'
import { offline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import axios from 'axios'

// Modules
import projects from './modules/projects'
import project from './modules/project'
import auth from './modules/auth'
import survey from './modules/survey'
import customer from './modules/customer'
import estimate from './modules/estimate'
import changePasswordForm from './modules/changePasswordForm'
import createProjectForm from './modules/createProjectForm'
// import scheduler from './../components/scheduler'
import employeeCreateProjectForm from './modules/employeeCreateProjectForm'
import teams from './modules/teams/'
import serviceWorker from './modules/serviceWorker'
import paperwork from './modules/paperwork/'
import installation from './modules/installation/'
import settingsForm from './modules/settingsForm'
import globalSettings from './modules/globalSettings/'
import notifications from './modules/notifications/'
import scheduler from './modules/scheduler/'

// Middleware
const loggerMiddleware = createLogger() // initialize Logger

// Reducers
const rootReducer = combineReducers({
  auth,
  projects,
  project,
  survey,
  customer,
  estimate,
  changePasswordForm,
  createProjectForm,
  employeeCreateProjectForm,
  teams,
  serviceWorker,
  paperwork,
  installation,
  settingsForm,
  globalSettings,
  notifications,
  scheduler,
})

// axios config for redux-offline
const effect = (effect, _action) => {
  // format and use the data from effect in axios manner
  const { json, ...restEffect } = effect

  // console.log('THE JSON', json)

  return axios({
    ...restEffect,
    data: json,
  })
}
const discard = (error, _action, _retries) => {
  const { request, response } = error
  if (!request) throw error // There was an error creating the request
  if (!response) return false // There was no response
  return 400 <= response.status && response.status < 500
}

const configureStore = initialState => {
  if (module.hot) {
    module.hot.accept(rootReducer, () => store.replaceReducer(rootReducer))
  }

  // const persistedState = loadState()

  const store = createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(
          applyMiddleware(thunk, loggerMiddleware),
          offline({
            persistOptions: {
              whitelist: ['survey'],
            },
            ...offlineConfig,
            effect,
            discard,
          })
        )
      : compose(
          applyMiddleware(thunk),
          offline({
            persistOptions: {
              whitelist: ['survey'],
            },
            ...offlineConfig,
            effect,
            discard,
          })
        )
  )

  // JWT
  if (getToken()) {
    setAuthorizationToken(getToken())
  }

  // persists the auth state
  // store.subscribe(() => {
  //   saveState({
  //     auth: store.getState().auth,
  //   })
  // })

  return store
}

export default configureStore
