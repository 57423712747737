import styled from 'styled-components'
import { breakpoints, navSize } from './../../../helpers/foundation'

export const MainContent = styled.div`
  margin-top: ${navSize.small};
  overflow-x: hidden;
  height: ${props =>
    props.fullWidth && props.smallNav
      ? `calc(100vh - ${navSize.small})`
      : `calc(100vh - ${navSize.large})`};

  @media screen and (max-width: ${breakpoints.medium}px) {
    height: calc(100vh - ${navSize.small});
  }

  @media screen and (min-width: ${breakpoints.medium}px) {
    ${props => {
      if (props.smallNav) {
        return `
          margin-top: ${navSize.small};
        `
      } else {
        return `
          margin-top: ${navSize.large};
        `
      }
    }}
  }

  .tabs-responsive-container {
    display: none;
    @media screen and (max-width: 1270px) {
      display: block;
    }
  }
`

export default MainContent
