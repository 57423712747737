import React, { Component } from 'react'
import styled from 'styled-components'
import { hideVisually } from 'polished'
import shortid from 'shortid'
import { isAfter } from 'date-fns'
import { createSkeletonProvider } from '@trainline/react-skeletor'
import { colors, breakpoints } from '../../../helpers/foundation'
import { canAccess } from './../../../helpers/auth'
import isRole from './../../../helpers/roles'
import { PlusIconCircle } from '../../sharedComponents/StyledIcons'

import ProjectList from './ProjectList'
import Column from './Column'
import ProjectBoard from './../../sharedComponents/layouts/ProjectBoard-NEW'
import Panel from '../../sharedComponents/Panel'
import EmployeeCreateProjectFormContainer from '../../../containers/employeeCreateProjectFormContainer'
import ProjectCustomerModal from './ProjectCustomerModal'
import DeleteProjectModal from './DeleteProjectModal'

const NewProjectAction = styled.div`
  position: fixed;
  bottom: 45px;
  left: ${props => (props.newProjectPanelOpen ? '460px' : '60px')};
  z-index: 7;
  transition: 0.2s ease-out left;

  @media screen and (max-width: ${breakpoints.medium}px) {
    ${props => {
      if (props.newProjectPanelOpen) {
        return hideVisually()
      }
    }}
  }
`

class ProjectsDashboard extends Component {
  state = {
    alertShowing: true,
    modalOpen: false,
    deleteProjectModalOpen: false,
    modalInfo: null,
    deleteProjectModalInfo: null,
    newProjectPanelOpen: false,
  }

  componentDidMount = () => {
    if (this.props.stage) {
      // only fetch if that stage isnt already cached
      if (this.props.stage && !this.props.stagesFetched[this.props.stage]) {
        this.props.fetchData(this.props.stage)
      }

      if (
        this.props.stage === 'survey' &&
        !this.props.quotesFetched &&
        (this.props.quotes && this.props.quotes.length < 1)
      ) {
        this.props.fetchQuotes()
      }
    }
  }

  filterExpired = projects => {
    const today = new Date()

    return projects.filter(project => {
      if (project.estimate.expirationDate) {
        return isAfter(project.estimate.expirationDate, today)
      } else {
        return true
      }
    })
  }

  dismissTimer = () => {
    this.setState({ alertShowing: false })
  }

  openModal = info => {
    this.setState(prevState => ({
      modalOpen: true,
      modalInfo: { ...info },
    }))
  }

  openDeleteProjectModal = (itemId, opts) => {
    this.setState(prevState => ({
      deleteProjectModalOpen: true,
      deleteProjectModalInfo: {
        itemId,
        // if is quote, change wording from project to quote
        isQuote: opts.isQuote || false,
      },
    }))
  }

  closeModal = () => {
    this.setState(prevState => ({
      modalOpen: false,
      modalInfo: null,
    }))
  }

  closeDeleteProjectModal = () => {
    this.setState(prevState => ({
      deleteProjectModalOpen: false,
      deleteProjectModalInfo: null,
    }))
  }

  toggleNewProjectPanel = () => {
    this.setState(prevState => ({
      newProjectPanelOpen: !prevState.newProjectPanelOpen,
    }))
  }

  render() {
    const { modalOpen, deleteProjectModalOpen, modalInfo, deleteProjectModalInfo } = this.state
    const {
      user,
      stage,
      projects,
      isLoading,
      compact,
      globalSettings,
      deleteProject,
      deleteQuote,
    } = this.props

    if (stage === 'installation') {
      return (
        <React.Fragment>
          {modalOpen && <ProjectCustomerModal modalInfo={modalInfo} closeModal={this.closeModal} />}
          {deleteProjectModalOpen && (
            <DeleteProjectModal
              modalInfo={deleteProjectModalInfo}
              closeModal={this.closeDeleteProjectModal}
              deleteProject={deleteProject}
              deleteQuote={deleteQuote}
            />
          )}
          <ProjectBoard user={user} stage={stage}>
            <React.Fragment>
              <Column isFirst={true}>
                <ProjectList
                  title="Schedule Installation"
                  status="Schedule Installation"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Schedule Installation'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="Payment Pending"
                  status="Installation First Payment"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Installation First Payment'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="Installation Prep"
                  status="Installation Prep"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Installation Prep'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="Installation Pending"
                  status="Installation Pending"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Installation Pending'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  // globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="In Progress"
                  status="In Progress"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'In Progress'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="Inspection"
                  status="Inspection"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Inspection'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="Final Payment"
                  status="Final Payment"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Final Payment'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  // globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="Completed"
                  status="Completed"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(
                    item =>
                      stage === 'installation' &&
                      item[stage] &&
                      item[stage].installationStatus === 'Completed'
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  // globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
            </React.Fragment>
          </ProjectBoard>
        </React.Fragment>
      )
    }

    if (stage === 'paperwork') {
      return (
        <React.Fragment>
          {modalOpen && <ProjectCustomerModal modalInfo={modalInfo} closeModal={this.closeModal} />}
          {deleteProjectModalOpen && (
            <DeleteProjectModal
              modalInfo={deleteProjectModalInfo}
              closeModal={this.closeDeleteProjectModal}
              deleteProject={deleteProject}
              deleteQuote={deleteQuote}
            />
          )}
          <ProjectBoard user={user} stage={stage}>
            <React.Fragment>
              <Column isFirst={true}>
                <ProjectList
                  title="Gather Files"
                  status="Gather Files"
                  stage={stage}
                  compact={compact}
                  items={projects.filter(item => {
                    console.log('wut is item?', item)
                    return item[stage].status === 'Gather Files'
                  })}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
            </React.Fragment>
          </ProjectBoard>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        {modalOpen && <ProjectCustomerModal modalInfo={modalInfo} closeModal={this.closeModal} />}
        {deleteProjectModalOpen && (
          <DeleteProjectModal
            modalInfo={deleteProjectModalInfo}
            closeModal={this.closeDeleteProjectModal}
            deleteProject={deleteProject}
            deleteQuote={deleteQuote}
          />
        )}
        <ProjectBoard user={user} stage={stage}>
          {stage !== '' ? (
            <React.Fragment>
              {stage === 'survey' && (
                <>
                  <Panel
                    open={this.state.newProjectPanelOpen}
                    size="400"
                    color={colors.white}
                    attached={false}
                    padded={true}
                    position="left"
                    border="right"
                  >
                    <EmployeeCreateProjectFormContainer
                      toggleNewProjectPanel={this.toggleNewProjectPanel}
                      open={this.state.newProjectPanelOpen}
                    />
                  </Panel>
                  <NewProjectAction
                    newProjectPanelOpen={this.state.newProjectPanelOpen}
                    onClick={this.toggleNewProjectPanel}
                  >
                    <PlusIconCircle
                      fontSize={32}
                      color="white"
                      width={64}
                      height={64}
                      backgroundColor={this.state.newProjectPanelOpen ? 'danger' : 'primary'}
                      isOpen={this.state.newProjectPanelOpen}
                      toggleFunc={this.toggleNewProjectPanel}
                    />
                  </NewProjectAction>
                </>
              )}

              {stage === 'survey' && (
                <Column isFirst={true}>
                  <ProjectList
                    title="Pending Quotes"
                    status="Pending Quotes"
                    stage={stage}
                    compact={compact}
                    isQuotes={true}
                    items={this.props.quotes}
                    user={user}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    openDeleteProjectModal={this.openDeleteProjectModal}
                    history={this.props.history}
                  />
                </Column>
              )}

              {stage === 'survey' && (
                <Column>
                  <ProjectList
                    title="To Schedule"
                    status="To Schedule"
                    stage={stage}
                    compact={compact}
                    items={projects.filter(item => item[stage].status === 'To Schedule')}
                    user={user}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    globalSettings={globalSettings}
                    openDeleteProjectModal={this.openDeleteProjectModal}
                    history={this.props.history}
                  />
                </Column>
              )}

              <Column>
                <ProjectList
                  title="To Do"
                  status="To Do"
                  stage={stage}
                  compact={compact}
                  items={this.filterExpired(
                    projects.filter(
                      item =>
                        item[stage].status === 'To Do' &&
                        (canAccess(item, user) || isRole(user).customerService)
                    )
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="In Progress"
                  status="In Progress"
                  stage={stage}
                  compact={compact}
                  items={this.filterExpired(
                    projects.filter(
                      item =>
                        item[stage].status === 'In Progress' &&
                        (canAccess(item, user) || isRole(user).customerService)
                    )
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>
              <Column>
                <ProjectList
                  title="To Revise"
                  status="To Revise"
                  stage={stage}
                  compact={compact}
                  items={this.filterExpired(
                    projects.filter(
                      item =>
                        item[stage].status === 'To Revise' &&
                        (canAccess(item, user) || isRole(user).customerService)
                    )
                  )}
                  user={user}
                  openModal={this.openModal}
                  closeModal={this.closeModal}
                  globalSettings={globalSettings}
                  openDeleteProjectModal={this.openDeleteProjectModal}
                  history={this.props.history}
                />
              </Column>

              {/* only allow admins and survey managers to see this column */}
              {isRole(user).admin ||
              (stage === 'survey' && isRole(user).surveyManager) ||
              (stage === 'estimate' && isRole(user).estimateManager) ? (
                <Column>
                  <ProjectList
                    title="To Review"
                    status="To Review"
                    stage={stage}
                    compact={compact}
                    items={this.filterExpired(
                      projects.filter(
                        item =>
                          item[stage].status === 'To Review' &&
                          (canAccess(item, user) || isRole(user).customerService)
                      )
                    )}
                    user={user}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    globalSettings={globalSettings}
                    openDeleteProjectModal={this.openDeleteProjectModal}
                    history={this.props.history}
                  />
                </Column>
              ) : null}
              {stage === 'estimate' && (
                <Column>
                  <ProjectList
                    title="Pending Approval"
                    status="Pending Approval"
                    stage={stage}
                    compact={compact}
                    items={this.filterExpired(
                      projects.filter(
                        item =>
                          item[stage].status === 'Pending Approval' &&
                          (canAccess(item, user) || isRole(user).customerService)
                      )
                    )}
                    user={user}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    // globalSettings={globalSettings}
                    openDeleteProjectModal={this.openDeleteProjectModal}
                    history={this.props.history}
                  />
                </Column>
              )}
              {stage === 'estimate' &&
                (isRole(user).admin ||
                  isRole(user).estimateManager ||
                  isRole(user).customerService) && (
                  <Column>
                    <ProjectList
                      title="Pending Payment"
                      status="Pending Payment"
                      stage={stage}
                      compact={compact}
                      items={this.filterExpired(
                        projects.filter(
                          item =>
                            item[stage].status === 'Pending Payment' &&
                            (isRole(user).admin ||
                              isRole(user).estimateManager ||
                              isRole(user).customerService)
                        )
                      )}
                      user={user}
                      openModal={this.openModal}
                      closeModal={this.closeModal}
                      // globalSettings={globalSettings}
                      openDeleteProjectModal={this.openDeleteProjectModal}
                      history={this.props.history}
                    />
                  </Column>
                )}
              {stage === 'estimate' && (
                <Column>
                  <ProjectList
                    title="Expired"
                    stage={stage}
                    compact={compact}
                    items={projects.filter(
                      item =>
                        isAfter(new Date(), item['estimate'].expirationDate) &&
                        (canAccess(item, user) || isRole(user).customerService)
                    )}
                    user={user}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    // globalSettings={globalSettings}
                    openDeleteProjectModal={this.openDeleteProjectModal}
                    history={this.props.history}
                  />
                </Column>
              )}
              {stage !== 'survey' && stage !== 'estimate' && (
                <Column>
                  <ProjectList
                    title="Completed"
                    status="Completed"
                    stage={stage}
                    compact={compact}
                    items={this.filterExpired(
                      projects.filter(
                        item =>
                          item[stage].status === 'Completed' &&
                          (canAccess(item, user) || isRole(user).customerService)
                      )
                    )}
                    user={user}
                    openModal={this.openModal}
                    closeModal={this.closeModal}
                    globalSettings={globalSettings}
                    openDeleteProjectModal={this.openDeleteProjectModal}
                    history={this.props.history}
                  />
                </Column>
              )}
            </React.Fragment>
          ) : (
            <span className="message">To see projects, first select a stage.</span>
          )}
        </ProjectBoard>
      </React.Fragment>
    )
  }
}

// export default ProjectsDashboard

function generateFakeQuotes() {
  const items = []
  for (let i = 0; i < 3; i++) {
    const item = {}
    item._id = shortid.generate()
    item.additional_project_notes = 'Not loaded'
    item.address = '1234 Faker St'
    item.city = 'Faketobia'
    item.phone = ' '
    item.state = 'AA'
    item.zip = '12345'
    item.email = 'fake@email.com'
    item.full_name = 'Fake User'
    item.installation_options = 'Not loaded'
    item.isValid = true
    item.marketing_source = 'Not loaded'
    item.type_of_fence = 'Not loaded'
    item.createdAt = new Date()
    item.uploadedAt = new Date()
    item.fakeItem = true

    items.push(item)
  }

  return items
}

function generateFakeProjects(stage) {
  const stages = {
    survey: ['To Schedule', 'To Do', 'In Progress', 'To Revise', 'To Review'],
    estimate: [
      'To Do',
      'In Progress',
      'To Revise',
      'To Review',
      'Pending Approval',
      'Pending Payment',
      'Expired',
    ],
    paperwork: ['Gather Files'],
    installation: [
      'Schedule Installation',
      'Installation First Payment',
      'Installation Prep',
      'Installation Pending',
      'In Progress',
      'Inspection',
      'Final Payment',
      'Completed',
    ],
  }

  const items = []
  for (let i = 0; i < stages[stage].length; i++) {
    for (let z = 0; z < 3; z++) {
      const item = {}
      item._id = shortid.generate()
      item.slug = ' '
      item.name = 'Real Fake Name'
      item.additionalClientInfo = {
        address: '1234 Faker St',
        city: 'Faketobia',
        phone: ' ',
        state: 'AA',
        zip: '12345',
      }
      item.archived = false
      item.currentPayment = 0
      item.customer = {
        createdAt: new Date(),
        displayName: ' ',
        email: ' ',
        phone: ' ',
        projects: [],
        role: 'Customer',
      }
      item.survey =
        stage === 'survey'
          ? {
              status: stages[stage][i],
              _id: shortid.generate(),
            }
          : {}
      item.estimate =
        stage === 'estimate'
          ? {
              status: stages[stage][i],
              _id: shortid.generate(),
            }
          : {}
      item.paperwork =
        stage === 'paperwork'
          ? {
              status: stages[stage][i],
              _id: shortid.generate(),
            }
          : {}
      item.installation =
        stage === 'installation'
          ? {
              installationStatus: stages[stage][i],
              _id: shortid.generate(),
            }
          : {}
      item.scheduled = true
      item.stage = stage
      item.type = 'fence'
      item.versions = []
      item.members = {
        survey: [],
        estimate: [],
      }
      item.createdAt = new Date()
      item.updatedAt = new Date()
      item.fakeItem = true

      items.push(item)
    }
  }
  return items
}

export default createSkeletonProvider(
  // need to create a generator to generate fake project cards
  props => ({
    projects: [...props.projects, ...generateFakeProjects(props.stage)],
    quotes: generateFakeQuotes(),
  }),
  props => props.isLoading && props.projects.length < 1,
  {
    backgroundColor: 'grey',
    color: 'grey',
    borderColor: 'grey',
  }
)(ProjectsDashboard)
