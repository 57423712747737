import React, { Component } from 'react'
import styled from 'styled-components'
import { breakpoints, navSize, controlsSize, colors } from './../../../helpers/foundation'
import { isRole } from '../../../helpers/roles'
import Tabs from './../Tabs'
import { SurveyIcon, EstimateIcon, PaperworkIcon, InstallationIcon } from './../StyledIcons'

const initState = {
  pos: 0,
  dragging: false,
  currentX: 0,
  width: null,
  stage: '',
}

class ProjectBoard extends Component {
  state = initState

  componentDidMount = () => {
    if (this.props.stage !== '') {
      window.addEventListener('mousemove', this.handleMouseMove)
      window.addEventListener('mouseup', this.handleMouseUp)

      this.setState({
        ...(this.refs.container && { width: this.refs.container.offsetWidth }),
        // width: this.refs.container.offsetWidth,
        stage: this.props.stage,
      })
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('mousemove', this.handleMouseMove)
    window.removeEventListener('mouseup', this.handleMouseUp)
  }

  handleMouseDown = e => {
    if (e.button !== 0 || e.target.closest('form')) return

    const containerWidth = this.refs.container.offsetWidth
    const containerLeft = this.refs.container.scrollLeft

    this.setState({
      dragging: true,
      width: containerWidth,
      currentX: e.pageX + containerLeft,
      pageX: e.pageX,
    })

    e.stopPropagation()
    e.preventDefault()
  }

  handleMouseUp = e => {
    this.setState({ dragging: false })
    e.stopPropagation()
    e.preventDefault()
  }

  handleMouseMove = e => {
    const { dragging, currentX, pos } = this.state

    if (!dragging) return

    this.setState(
      {
        pos: -e.pageX + currentX,
      },
      () => {
        this.refs.container.scrollLeft = pos
      }
    )

    e.stopPropagation()
    e.preventDefault()
  }

  resetBoard = () => {
    this.setState(initState, () => {
      if (this.refs.wrapper) {
        this.refs.wrapper.scrollLeft = 0
      }
    })
  }

  render() {
    const { dragging } = this.state
    const { user } = this.props

    if (this.props.hasBreakpoint && this.props.breakpoint) {
      if (this.props.currentWidth > this.props.breakpoint) {
        return <div>{this.props.children}</div>
      } else {
        return (
          <SchedulerLayout dragging={dragging} alternate={this.props.alternate}>
            <div className="wrapper" ref="container" onMouseDown={this.handleMouseDown}>
              <div className="container" ref="wrapper">
                {this.props.children}
              </div>
            </div>
          </SchedulerLayout>
        )
      }
    }

    // add tabs to be rendered based on users role
    // ie - surveyor should not see tabs other than 'Survey'
    const tabs = []

    const surveyTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).survey ||
      isRole(this.props.user).customerService
        ? true
        : false
    const estimateTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).estimate ||
      isRole(this.props.user).customerService
        ? true
        : false
    const paperworkTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).estimate ||
      isRole(this.props.user).customerService
        ? true
        : false
    const installationTabVisible =
      isRole(this.props.user).admin ||
      isRole(this.props.user).estimate ||
      isRole(this.props.user).customerService
        ? true
        : false

    if (surveyTabVisible) {
      tabs.push({
        name: 'Survey',
        color: 'blue',
        link: '/projects/surveys',
        icon: <SurveyIcon />,
      })
    }
    if (estimateTabVisible) {
      tabs.push({
        name: 'Estimate',
        color: 'purple',
        link: '/projects/estimates',
        icon: <EstimateIcon />,
      })
    }
    if (paperworkTabVisible) {
      tabs.push({
        name: 'Paperwork',
        color: 'orange',
        link: '/projects/paperwork',
        icon: <PaperworkIcon />,
      })
    }
    if (installationTabVisible) {
      tabs.push({
        name: 'Install',
        color: 'pink',
        link: '/projects/installation',
        icon: <InstallationIcon />,
      })
    }

    return (
      <ColumnsLayout dragging={dragging} alternate={this.props.alternate}>
        <div className="wrapper" ref="wrapper">
          {/* this will be hidden when screen is below certain width, and one in Projects/index.js will be used */}
          <div className="tabs-container">
            <div className="inner">
              <Tabs type="link" tabs={tabs} />
            </div>
          </div>
          <div className="container" ref="container" onMouseDown={this.handleMouseDown}>
            {this.props.children}
          </div>
        </div>
      </ColumnsLayout>
    )
  }
}

export const ColumnsLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 1.5em 1.5em;
  height: calc(100vh - ${navSize.small} - 78px);

  @media screen and (min-width: ${breakpoints.small}px) {
    height: calc(100vh - ${navSize.small} - 78px);
  }

  @media screen and (min-width: ${breakpoints.medium}px) {
    /* account for searchbar area */
    height: calc(100vh - ${navSize.large} - 78px);
    padding: 0 2.5em 2.5em;
  }

  .wrapper {
    ${'' /* position: relative; */}
    height: 100%;
    overflow: hidden;
    background-color: ${colors.grey7};
    padding-top: 3.5em;

    @media screen and (max-width: 1680px) {
      padding-left: 1em;
      padding-right: 1em;
    }

    .tabs-container {
      position: absolute;
      top: -4.5%;
      left: 0;
      right: 0;
      z-index: 4;
      .inner {
        width: 100%;
        max-width: 1600px;
        margin: auto;
      }

      @media screen and (max-width: 1680px) {
        left: 2.5em;
        right: 2.5em;
      }

      @media screen and (max-width: 1270px) {
        display: none;
      }
    }
  }

  .container {
    position: relative;
    height: 100%;
    display: flex;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto 10px;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    /* Scrollbars */
    ::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }
    ::-webkit-scrollbar-track-piece {
      background: ${colors.lightGrey};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background: ${colors.grey};
      border-radius: 4px;
    }
  }

  /* TODO: Split into component */
  .message {
    color: #ced4da;
    font-size: 24px;
    text-align: center;
    line-height: 1.15;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const SchedulerLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 106px);
  /* width of fixed week */
  position: relative;
  overflow: auto;

  .wrapper {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0.65em;
    overflow-y: hidden;

    @media screen and (max-width: ${breakpoints.medium}px) {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }

  .container {
    position: relative;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;

    /* Scrollbars */
    ::-webkit-scrollbar {
      height: 12px;
      width: 12px;
      padding: 0 1em;
    }
    ::-webkit-scrollbar-track-piece {
      background: ${colors.lightGrey};
    }

    ::-webkit-scrollbar-thumb:horizontal {
      background: ${colors.grey};
    }

    @media screen and (max-width: ${breakpoints.medium}px) {
      overflow: hidden;
    }
  }

  /* TODO: Split into component */
  .message {
    color: #ced4da;
    font-size: 24px;
    text-align: center;
    line-height: 1.15;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export default ProjectBoard
