import React from 'react'
import styled from 'styled-components'
import { hideVisually, darken } from 'polished'
import { colors } from '../../helpers/foundation'
import { CameraIcon } from './StyledIcons'

// TODO: Doesn't really need to exist. Consolidate button / label components

const Field = styled.label`
  display: inline-block;
  flex-shrink: 1;
  flex-grow: 0;
  background-color: ${props => (props.color ? colors[props.color] : colors.primary)};
  border: 1px solid
    ${props =>
      props.color ? (props.color === 'white' ? colors.base : colors[props.color]) : colors.primary};
  border-radius: 4px;
  color: ${props => (props.color && props.color === 'white' ? colors.base : colors.white)};
  outline: 0;
  /* padding: 0.5em 1.5em; */
  padding: ${props => {
    if (props.noText) {
      return '1em 2em'
    }
    if (props.variant && props.variant.includes('nm')) {
      return '1em 3em'
    }
    if (props.variant && props.variant.includes('lg')) {
      return '1em 3em'
    } else {
      return '0.5em 1.5em'
    }
  }};
  font-weight: 700;
  font-size: ${props => {
    if (props.variant && props.variant.includes('lg')) {
      return '1.25em'
    } else if (props.variant && props.variant.includes('nm')) {
      return '0.75em'
    }
  }};
  line-height: 1.15;
  margin-right: ${props => (props.fluid ? '0em' : '0.5em')};
  margin-bottom: 0;
  cursor: pointer;
  text-transform: capitalize;
  transition: 0.15s ease-in-out;
  width: ${props => props.fluid && '100%'};
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    background-color: ${props =>
      props.color && props.color === 'white' ? colors.base : darken(0.05, colors.primary)};
    ${props => {
      if (props.color && props.color === 'white') {
        return `
          color: ${colors.white}
        `
      }
    }};
  }

  > input[type='file'] {
    ${hideVisually()};
  }

  .icon {
    position: relative;

    > * {
      position: relative;
      top: 3px;
    }
  }
`

const ImageInput = props => {
  return (
    <Field {...props}>
      {props.text ? (
        props.text
      ) : (
        <div className="icon">
          <CameraIcon />
        </div>
      )}
      {props.icon && <div className="icon">{props.icon}</div>}
      <input
        className="imageInput"
        type="file"
        name={props.name}
        onChange={props.handleFile}
        accept="image/jpeg"
      />
    </Field>
  )
}

export default ImageInput
